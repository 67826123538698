export default {
    "projects": [
        {
            "Apps": [
                {
                    "name": "Task Manager",
                    "description": "Task Manager App built with React/Redux",
                    "type": "react",
                    "img": require('./assets/images/task.png'),
                    "link": "https://simonjduckett.github.io/Task-Manager/"
                },
                {
                    "name": "Events Calendar",
                    "description": "Opening times & Special Events Calendar with user interface",
                    "type": "javascript",
                    "img": require('./assets/images/events.png'),
                    "link": "https://www.simonguitar.co.uk/eventscalendar"
                }
            ],
            "Websites": [
                {
                    "name": "Ai drone",
                    "description": "Online music store for artists and content creators",
                    "type": "react",
                    "img": require('./assets/images/IMG_1485.JPG'),
                    "link": "https://aidronebeats.com/"
                },
                {
                    "name": "Artist Template",
                    "description": "Site template for a music artist",
                    "type": "php",
                    "img": require('./assets/images/artistbox.png'),
                    "link": "https://www.simonguitar.co.uk/Artist/"
                },
                
                {
                    "name": "Simon's store",
                    "description": "Front end for a clothing store",
                    "type": "react",
                    "img": require('./assets/images/simonsbox.png'),
                    "link": "https://simonjduckett.github.io/simons-store/"
                }
                // {
                //     "name": "Katy's Dance Academy",
                //     "description": "Website for local dance school",
                //     "type": "wordpress",
                //     "img": require('./assets/images/dance.png'),
                //     "link": "https://www.katysdanceacademy.co.uk/"
                // }
            ],
            "Games": [
                {
                    "name": "Mystery Box 3",
                    "description": "Retro video game built with vanilla js",
                    "type": "javascript",
                    "img": require('./assets/images/mystery.png'),
                    "link": "https://simonjduckett.github.io/Mystery-Box-3/"
                }
            ],
            // "music": [
            //     {
            //         "name": "Ai drone",
            //         "description": "Online music store for artists and content creators",
            //         "type": "HipHop",
            //         "img": require('./assets/images/IMG_1485.JPG'),
            //         "link": "https://aidronebeats.com/"
            //     },
            //     {
            //         "name": "Follow my lead",
            //         "description": "6 track juke influenced E.P",
            //         "type": "broken beat",
            //         "img": require('./assets/images/follow.jpg'),
            //         "link": "https://duckett.bandcamp.com/album/follow-my-lead"
            //     },
            //     {
            //         "name": "Parade",
            //         "description": "5 track E.P",
            //         "type": "footwork",
            //         "img": require('./assets/images/parade.jpg'),
            //         "link": "https://duckett.bandcamp.com/album/parade"
            //     },
            //     {
            //         "name": "Responsible Use Of Synthesizers EP",
            //         "description": "E.P released on Ground Mass Music",
            //         "type": "footwork",
            //         "img": require('./assets/images/ruos.jpg'),
            //         "link": "https://groundmassmusic.bandcamp.com/album/responsible-use-of-synthesizers-ep"
            //     }
            // ],
            // "education": [
            //     {
            //         "name": "Simon Guitar",
            //         "description": "php",
            //         "type": "Web app",
            //         "img": require('./assets/images/simonguitarbox.png'),
            //         "link": "https://www.simonguitar.co.uk/"
            //     }
            // ]
        }
    ]
}